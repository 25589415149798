import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Features from "./Features";
import Footer from "./Footer";
import Marquee from "react-fast-marquee";
import Getid from "./Getid";

const Homepage = () => {


  useEffect(() => {
    ReactGA.initialize("G-RE9Y7Y875F");
    ReactGA.send("pageview");
  }, []);

  const handleonclick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank");
  };
  return (
    <div className="">
  
      {/* <Navbar /> */}
      <Hero handleonclick={handleonclick} />
      <Marquee autoFill className="bg-black text-white  p-5 fixed top-0 z-35">
        <span class="mx-4 text-lg font-semibold">🚀 Get 10% Discount </span>
        <span class="mx-4 text-lg font-semibold">🌟 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🔥 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🚀 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🌟 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🔥 Get 10% Discount</span>
      </Marquee>

      {/* <Features /> */}
      <Footer handleonclick={handleonclick} />
      {/* <Getid/> */}
    </div>
  );
};

export default Homepage;
