import React from "react";

const Hero = ({ handleonclick }) => {
  return (
    <div
      onClick={handleonclick}
      className="w-full md:h-[100vh] relative h-[40vh] bg-cover md:bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url("https://t3.ftcdn.net/jpg/01/59/07/92/360_F_159079250_3oo6EuNF6gPjNe3ABeDhMjzzEDlGVdjO.jpg")`,
      }}
    >
      <div className="w-full h-full bg-black/30 absolute top-0 right-0  flex justify-center items-center">
        <div className="absolute right-10 top-32">
          <h1 className="md:text-7xl text-2xl text-white uppercase">
            Special offers
          </h1>
          <h1 className="md:text-7xl text-2xl text-red-600 font-bold uppercase">
            100% Bonos
          </h1>
          <h1 className="text-white md:text-5xl text-md mt-5 uppercase absolute ">
            -----last chance ----
          </h1>
        </div>
        *{" "}
      </div>
      <div className="absolute  bottom-10  md:bottom-52 md:right-44 right-5">
        <button
          onClick={handleonclick}
          className="bg-gradient-to-r from-yellow-400 font-bold animate-bounce uppercase to text-white border-2 boder-white rounded-md md:px-20 md:py-3 py-2 px-10 to-orange-400 p-5"
        >
          Get !d now
        </button>
      </div>
    </div>
  );
};

export default Hero;
