import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'; // Social icons

const Footer = ({handleonclick}) => {
  return (
    <div className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-6">
        {/* Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* About Section */}
          <div>
            <h3 className="text-2xl font-bold mb-4">About Us</h3>
            <p className="text-lg">
              We are India's premier Fantasy Cricket platform, offering an immersive and exciting experience for cricket enthusiasts.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 onClick={handleonclick} className="text-2xl font-bold mb-4">Quick Links</h3>
            <ul>
              <li>
                <h1  onClick={handleonclick} className="text-lg hover:text-yellow-400">Privacy Policy</h1>
              </li>
              <li>
                <h1 onClick={handleonclick}  className="text-lg hover:text-yellow-400">Terms & Conditions</h1>
              </li>
              <li>
                <h1 onClick={handleonclick} className="text-lg hover:text-yellow-400">Contact Us</h1>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div>
            <h3  className="text-2xl font-bold mb-4">Follow Us</h3>
            <div className="flex justify-center space-x-6">
              <h1 onClick={handleonclick}  className="text-2xl hover:text-blue-500">
                <FaFacebook />
              </h1>
              <h1 onClick={handleonclick}  className="text-2xl hover:text-blue-400">
                <FaTwitter />
              </h1>
              <h1 onClick={handleonclick}  className="text-2xl hover:text-pink-500">
                <FaInstagram />
              </h1>
              <h1 onClick={handleonclick} className="text-2xl hover:text-blue-700">
                <FaLinkedin />
              </h1>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Wickbuzz. All rights reserved.</p>
        </div>

        {/* Additional Disclaimer */}
        <div className="mt-4 text-center text-sm text-gray-400">
          <p className="mb-2">This platform is intended for users aged 18+ only. Please play responsibly.</p>
          <p>No real money is involved in the games. All activities on the platform are for entertainment purposes only.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
